import React, { useState } from "react";
import GeneralLayout from "../../components/layouts/GeneralLayout/generalLayout";
import { Box } from "../../components/lib/Box/styles";
import { FaqPageWrapper } from "./faqs.styles";
import DaminiLogo from "../../assets/images/damini-logo.svg";
import { faqs } from "../../utils/data/faqs";

const FaqPage = () => {
  const [activeQuestion, setActiveQuestion] = useState(
    "Question 1 can be something about the stream"
  );

  return (
    <GeneralLayout>
      <FaqPageWrapper>
        <Box className="damini_logo__wrap">
          <img src={DaminiLogo} alt="" />
        </Box>
        <span className="faqheader__text">
          We know you have a lot of questions… We’ll do our best to handle
          everything in this section.
        </span>

        <Box className="faq__box">
          {faqs.map((item, idx) => (
            <Box className="faq__item">
              <h4
                className="question"
                id={item.question === activeQuestion ? "active__question" : ""}
                onClick={() => setActiveQuestion(item.question)}
              >
                {item.question}
              </h4>
              {item.question === activeQuestion && (
                <span className="answer">{item.answer}</span>
              )}
            </Box>
          ))}
        </Box>
      </FaqPageWrapper>
    </GeneralLayout>
  );
};

export default FaqPage;
