import styled from "styled-components";
import { Box } from "../../components/lib/Box/styles";
import BgImageWeb from "../../assets/images/burna-carousel-web.png";

export const PreviewPageWrapper = styled(Box)`
  padding: 0 5% 25px 20px;
  height: calc(100vh - 110px);

  .damini__inlagos__art {
    height: 100%;
    background-image: url(${BgImageWeb});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px 0;
    align-items: flex-end;

    button {
      span {
        font-size: 1em;
      }
    }
  }
  @media (max-width: 800px) {
    height: 56vh;
    padding: 0 20px 10px 20px;
  }

  @media (max-width: 385px) {
    height: 45vh;
  }
`;
