import { CURRENT_USER, IS_PLAYER_ACTIVE } from "./types";

export const Reducer = (state, { type, payload }) => {
  switch (type) {
    case CURRENT_USER:
      return {
        ...state,
        user: payload || undefined,
      };

    case IS_PLAYER_ACTIVE:
      return {
        ...state,
        isPlayerActive: payload || null,
      };

    default:
      return state;
  }
};
