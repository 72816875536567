// Mobile footer for the general layout

import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FacebookIcon,
  InstaIcon,
  TwitterIcon,
} from "../../../../assets/images/svgExports";
import { Box } from "../../../lib/Box/styles";
import { FooterStyles } from "./footer.styles";
import MtnLogo from "../../../../assets/images/mtn-logo.svg";

const Footer = ({ noFullFooter }) => {
  const location = useLocation();

  return (
    <FooterStyles noFullFooter={noFullFooter}>
      {location.pathname === "/stream" ? (
        <Box className="mtnlinks__wrap">
          <Box className="logos__wrap">
            <img src={MtnLogo} alt="" />
          </Box>
          <Link to="#">
            Top up Data Dial *131#’ and Borrow Data Dial *606#’
          </Link>
        </Box>
      ) : (
        <Box className="links__wrap">
          <Box className="authlinks__wrap">
            <Link to="#">Login&nbsp;/</Link>&nbsp;<Link to="#">Sign Up</Link>
          </Box>

          <Link
            to="/faqs"
            id={location.pathname === "/faqs" ? "faq__active" : ""}
          >
            FAQs
          </Link>
        </Box>
      )}
    </FooterStyles>
  );
};

export default Footer;
