import styled from "styled-components";
import { Box } from "../../components/lib/Box/styles";

export const StreamPageWrapper = styled(Box)`
  padding: 20px 3%;
  justify-content: space-between;
  align-items: flex-start;
  min-height: calc(100vh - 180px);

  .video__wrap {
    width: 70%;
    border: 1px solid #707070;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;

    iframe {
      width: 100%;
      height: auto;
    }

    .video__header {
      padding-left: 20px;
      font-size: 0.9em;
      color: #fff;
      position: relative;
      margin-bottom: 20px;
      ::before {
        content: "";
        border-radius: 100%;
        width: 5px;
        height: 5px;
        background-color: #ed1651;
        position: absolute;
        left: 0;
        top: 45%;
      }
    }
  }
  .chat__wrap {
    width: 27%;
    border: 1px solid #fff;
    padding: 15px;
  }
  @media (max-width: 800px) {
    padding: 0 20px 10px 20px;
    flex-direction: column;
    justify-content: flex-start;

    .video__wrap {
      width: 100%;

      iframe {
        height: 400px !important;
      }
      .video__header {
        font-size: 0.7em;
      }
    }

    .chat__wrap {
      width: 100%;
      margin-top: 50px;
    }
  }

  @media (max-width: 500px) {
    .video__wrap {
      iframe {
        height: 250px !important;
      }
    }
  }
`;
