import React, { useState, useContext } from "react";
import { AtAllIcon, YesKeIcon } from "../../assets/images/svgExports";
import { Box } from "../../components/lib/Box/styles";
import Button from "../../components/lib/Button";
import { LandingPageWrapper } from "./landingPage.styles";
import DaminiLogo from "../../assets/images/damini-logo.svg";
// import DaminiLogoGif from "../../assets/images/damini-gif.gif";
import { MainContext } from "../../context/mainContext";
import { IS_PLAYER_ACTIVE } from "../../context/types";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const [playing, setPlaying] = useState(false);
  const navigate = useNavigate();
  const {
    dispatch,
    state: { isPlayerActive, user },
  } = useContext(MainContext);

  const toggle = () => {
    navigate("/stream-preview");

    if (!!isPlayerActive) {
      return;
    } else {
      dispatch({ type: IS_PLAYER_ACTIVE, payload: !isPlayerActive });
      setPlaying(!playing);
    }
  };
  return (
    <LandingPageWrapper>
      <Box className="damini_logo__wrap">
        <img src={DaminiLogo} alt="" />
      </Box>
      <h5 className="header__text">Shey Gbedu de enter body?</h5>
      <Box className="buttons__wrap">
        <Button onClick={toggle}>
          <YesKeIcon width={45} />
          Yes Ké!
        </Button>

        <Button backgroundcolor="#4F4F4F">
          <AtAllIcon width={25} />
          At all…
        </Button>
      </Box>
    </LandingPageWrapper>
  );
};

export default LandingPage;
