import { createContext, useContext, useReducer } from "react";
import { Reducer } from "./reducer";
import React, { useRef } from "react";

export const MainContext = createContext({
  user: {},
  isPlayerActive: null,
});

export const MainProvider = ({ children }) => {
  const playerRef = useRef();
  const initialState = useContext(MainContext);
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <MainContext.Provider value={{ state, dispatch, playerRef }}>
      {children}
    </MainContext.Provider>
  );
};
