import styled from "styled-components";
import { Box } from "../../../lib/Box/styles";

export const FooterStyles = styled(Box)`
  .mtnlinks__wrap {
    justify-content: flex-start;
    border-top: 1px solid #707070;
    background-color: #000;
    position: fixed;
    bottom: 0;
    height: 70px;
    padding: 0 30px;
    flex-wrap: nowrap;

    .logos__wrap {
      width: 80px;
      margin-right: 20px;

      img {
        width: 100%;
      }
    }
    a {
      color: #fff;
      font-size: 0.9em;
    }

    @media (max-width: 650px) {
      padding: 0 3%;

      .logos__wrap {
        width: 60px;
        margin-right: 10px;
      }
      a {
        font-size: 0.6em;
      }
    }
    @media (max-width: 402px) {
      .logos__wrap {
        width: 50px;
      }
      a {
        font-size: 0.4em;
      }
    }
  }

  .links__wrap {
    background-color: #222222;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    height: 70px;
    padding: 0 20px;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 0.9em;
    }
    .authlinks__wrap {
      width: unset;
    }

    #faq__active {
      color: #ffc403;
    }

    @media (min-width: 800px) {
      display: none;
    }
  }
`;
