import React, { useContext, useState } from "react";
import { Box } from "../../../lib/Box/styles";
import { DesktopHeaderStyles } from "./header.styles";
import { Link, useLocation } from "react-router-dom";
import { MainContext } from "../../../../context/mainContext";
import PoprevLogo from "../../../../assets/images/poprev-logo.svg";
import LagosLogo from "../../../../assets/images/lagos-logo.png";
import UduxLogo from "../../../../assets/images/udux-logo.svg";
import MtnLogo from "../../../../assets/images/mtn-logo.svg";
import DaminiLogoGif from "../../../../assets/images/damini-gif.gif";

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { dispatch } = useContext(MainContext);
  const location = useLocation();

  return (
    <>
      <DesktopHeaderStyles
        showMobileNav={showMobileNav}
        path={location.pathname}
      >
        <Box className="logodate__wrap">
          <Link to="/">
            <img src={DaminiLogoGif} alt="" />{" "}
          </Link>
          <h4 className="header__date">01.01.2023</h4>
        </Box>

        <Box className="sponsorlogos__wrap">
          <img src={UduxLogo} alt="" />
          <img src={MtnLogo} alt="" />
          <img src={LagosLogo} alt="" />
          <img src={PoprevLogo} alt="" />
        </Box>
        {location.pathname !== "/stream" && (
          <Box className="authlinks__wrap">
            <Link to="#">Login</Link>
            <Link to="#">Sign Up</Link>
          </Box>
        )}
      </DesktopHeaderStyles>
    </>
  );
};

export default Header;
