import React from "react";
import GeneralLayout from "../../components/layouts/GeneralLayout/generalLayout";
import { Box } from "../../components/lib/Box/styles";
import { StreamPageWrapper } from "./streamPage.styles";

const StreamPage = () => {
  return (
    <GeneralLayout>
      <StreamPageWrapper>
        <Box className="video__wrap">
          <h4 className="video__header">LAGOS LOVES DAMINI</h4>

          <iframe
            title="live stream"
            src="https://player.vimeo.com/video/680770828?h=e7003a4f8c&color=f3b12d&byline=0&portrait=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ width: "100%", height: "550px" }}
            className="video"
            id="iframeId"
          ></iframe>
        </Box>

        <Box className="chat__wrap">
          <iframe
            src="https://vimeo.com/event/1913832/chat/"
            width="100%"
            height="590px"
            frameborder="0"
          ></iframe>
        </Box>
      </StreamPageWrapper>
    </GeneralLayout>
  );
};

export default StreamPage;
