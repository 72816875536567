import styled from "styled-components";
import { Box } from "../../../lib/Box/styles";

export const DesktopSidebarStyles = styled(Box)`
  justify-content: space-between;
  position: fixed;
  top: 120px;
  left: 0;
  height: calc(100vh - 120px);
  width: 100px;
  z-index: 9;
  flex-direction: column;
  /* justify-content: ${({ path }) =>
    path === "/faqs" ? "center" : "flex-end"}; */
  justify-content: flex-end;

  padding: 30px 0 15px 0;

  svg {
    margin-top: 30px;
    transition: all 0.3s ease;
    opacity: 0.7;

    :hover {
      opacity: 1;
    }
  }
  .faq__text {
    text-decoration: none;
    padding: 20px 10px;
    writing-mode: vertical-rl;
    font-size: 1.2em;
    color: #fff;
    margin-bottom: 70px;
    transition: all 0.3s ease;

    :hover {
      color: #ffc403;
    }
  }

  #faq__active {
    background-color: #ffc403;

    :hover {
      color: #fff;
    }
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
