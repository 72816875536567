import styled from "styled-components";
import { Box } from "../../../lib/Box/styles";

export const DesktopHeaderStyles = styled(Box)`
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  height: 120px;
  width: 100%;
  z-index: 9;
  align-items: flex-end;
  padding: ${({ path }) =>
    path === "/stream" ? "0 3% 0 40px" : "0 5% 0 80px"};

  .logodate__wrap {
    width: max-content;
    height: 100%;

    img {
      width: 100px;
      height: auto;
      cursor: pointer;
    }
    .header__date {
      color: #fff;
    }
  }
  .sponsorlogos__wrap {
    width: 22%;
    justify-content: space-between;
    margin-bottom: 20px;
    img {
      width: 18%;
      height: auto;
    }
  }
  .authlinks__wrap {
    width: max-content;
    height: 100%;

    a {
      color: #fff;
      text-decoration: none;
      margin-left: 20px;
    }
  }

  @media (max-width: 1000px) {
    .logodate__wrap {
      img {
        width: 70px;
      }
      .header__date {
        font-size: 0.9em;
      }
    }
    .sponsorlogos__wrap {
      margin-bottom: 30px;
    }
    .authlinks__wrap {
      a {
        font-size: 0.9em;
      }
    }
  }
  @media (max-width: 800px) {
    justify-content: center;
    padding: 0;
    height: 150px;

    .sponsorlogos__wrap,
    .authlinks__wrap {
      display: none;
    }

    .logodate__wrap {
      flex-direction: column;

      img {
        width: 100px;
      }
      .header__date {
        font-size: 1em;
      }
    }
  }
`;
