import { Modal } from "antd";
import styled from "styled-components";

export const LayoutWrap = styled.div`
  width: 100%;
  height: max-content;
  overflow-x: hidden;
  background-color: var(--blackPrimary);
  position: relative;

  .general__layout__main {
    z-index: 2;
    margin: ${({ path }) =>
      path === "/stream" ? "110px 0 0 0" : "110px 0 0 80px"};
    width: ${({ path }) => (path === "/stream" ? "100%" : "calc(100% - 80px)")};
    overflow-y: auto;

    .mobile__logosponsors__wrap {
      display: none;
      flex-direction: column;

      .broughtby__text {
        color: #ffffff;
        margin: 30px 0 15px 0;
      }
      .sponsorlogos__wrap {
        max-width: 300px;
        width: 90%;
        justify-content: space-between;

        img {
          width: 18%;
          height: auto;
        }
      }
    }

    @media (max-width: 800px) {
      margin: 170px 0 90px 0;
      width: 100%;
      .mobile__logosponsors__wrap {
        display: flex;
      }
    }
  }
`;
