import { useEffect, useRef, useContext } from "react";
import { LayoutWrap } from "./generalLayout.styles";
import Footer from "./Footer/footer";
import Header from "./Header/header";
import { MainContext } from "../../../context/mainContext";
import Sidebar from "./Sidebar/sidebar";
import PoprevLogo from "../../../assets/images/poprev-logo.svg";
import LagosLogo from "../../../assets/images/lagos-logo.png";
import UduxLogo from "../../../assets/images/udux-logo.svg";
import MtnLogo from "../../../assets/images/mtn-logo.svg";
import { Box } from "../../lib/Box/styles";
import { useLocation } from "react-router-dom";

const GeneralLayout = ({ noFullFooter, children }) => {
  const location = useLocation();
  const {
    dispatch,
    state: {},
  } = useContext(MainContext);
  const parent = useRef();

  return (
    <LayoutWrap path={location.pathname}>
      <Header />
      {location.pathname !== "/stream" && <Sidebar />}
      <main className="general__layout__main">
        {children}

        {location.pathname !== "/stream" && (
          <Box className="mobile__logosponsors__wrap">
            <span className="broughtby__text">Brought to you by</span>

            <Box className="sponsorlogos__wrap">
              <img src={UduxLogo} alt="" />
              <img src={MtnLogo} alt="" />
              <img src={LagosLogo} alt="" />
              <img src={PoprevLogo} alt="" />
            </Box>
          </Box>
        )}
      </main>
      <Footer />
    </LayoutWrap>
  );
};

export default GeneralLayout;
