import React, { useContext, useState } from "react";
import { Box } from "../../../lib/Box/styles";
import { DesktopSidebarStyles } from "./sidebar.styles";
import { Link, useLocation } from "react-router-dom";
import { MainContext } from "../../../../context/mainContext";
import { InstaIcon, TwitterIcon } from "../../../../assets/images/svgExports";

const Sidebar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { dispatch } = useContext(MainContext);
  const location = useLocation();

  return (
    <DesktopSidebarStyles path={location.pathname}>
      <Link
        to="/faqs"
        className="faq__text"
        id={location.pathname === "/faqs" ? "faq__active" : ""}
      >
        FAQs
      </Link>
      <a href="#" rel="noreferrer">
        <InstaIcon width={28} />
      </a>
      <a href="#" rel="noreferrer">
        <TwitterIcon width={28} />
      </a>
    </DesktopSidebarStyles>
  );
};

export default Sidebar;
