import styled from "styled-components";
import { Box } from "../../components/lib/Box/styles";

export const FaqPageWrapper = styled(Box)`
  padding: 70px 5% 25px 5%;
  flex-direction: column;
  min-height: calc(100vh - 120px);

  .damini_logo__wrap {
    max-width: 250px;
    width: 70%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .faqheader__text {
    max-width: 500px;
    text-align: center;
    color: #fff;
    font-size: 0.9em;
    margin: 50px 0;
    line-height: 25px;
  }

  .faq__box {
    border: 1px solid #707070;
    border-bottom: none;
    max-width: 1500px;
    .faq__item {
      border-bottom: 1px solid #707070;
      flex-direction: column;
      align-items: flex-start;

      .question {
        padding: 22px 7%;
        color: #fff;
        font-size: 1.2em;
        width: 100%;
        cursor: pointer;
        transition: all 0.3s ease;

        :hover {
          background-color: #adf7b644;
        }
      }
      #active__question {
        background-color: #adf7b6;
        color: #000;
      }

      .answer {
        color: #fff;
        font-size: 1em;
        padding: 22px 7% 35px 7%;
        width: 100%;
        line-height: 25px;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 20px;

    .faq__box {
      .faq__item {
        .question {
          padding: 22px 5%;
          font-size: 0.8em;
        }

        .answer {
          padding: 22px 5% 35px 5%;
          font-size: 0.8em;
          line-height: 20px;
        }
      }
    }
  }
`;
