export const FacebookIcon = ({ width, color, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "44.789"}
    height="46.25"
    viewBox="0 0 44.789 46.25"
  >
    <path
      id="_021-facebook"
      data-name="021-facebook"
      d="M6.561,46.25H21.17V29.81H15.921V24.39H21.17V17.615a6.678,6.678,0,0,1,6.561-6.775h6.561v5.42H29.043a2.671,2.671,0,0,0-2.624,2.71v5.42h7.636l-.875,5.42H26.419V46.25h11.81a6.678,6.678,0,0,0,6.561-6.775V6.775A6.678,6.678,0,0,0,38.229,0H6.561A6.678,6.678,0,0,0,0,6.775v32.7A6.678,6.678,0,0,0,6.561,46.25ZM2.624,6.775A4.007,4.007,0,0,1,6.561,2.71H38.229a4.007,4.007,0,0,1,3.937,4.065v32.7a4.007,4.007,0,0,1-3.937,4.065H29.043V32.52H35.4l1.75-10.84h-8.11V18.97h7.873V8.13H27.731a9.349,9.349,0,0,0-9.185,9.485V21.68H13.3V32.52h5.249V43.54H6.561a4.007,4.007,0,0,1-3.937-4.065Zm0,0"
      fill={color || "#ffffff"}
    />
  </svg>
);

export const AtAllIcon = ({ width, color, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "22.287"}
    height="22.287"
    viewBox="0 0 22.287 22.287"
  >
    <path
      id="Icon_awesome-sad-tear"
      data-name="Icon awesome-sad-tear"
      d="M11.143.563A11.143,11.143,0,1,0,22.287,11.706,11.141,11.141,0,0,0,11.143.563Zm3.595,7.549A1.438,1.438,0,1,1,13.3,9.549,1.436,1.436,0,0,1,14.738,8.111ZM6.83,18.9a2.134,2.134,0,0,1-2.157-2.112c0-.9,1.281-2.714,1.869-3.5a.358.358,0,0,1,.575,0c.589.782,1.869,2.6,1.869,3.5A2.134,2.134,0,0,1,6.83,18.9Zm.719-7.908A1.438,1.438,0,1,1,8.987,9.549,1.436,1.436,0,0,1,7.549,10.987ZM15.2,17.916a5.272,5.272,0,0,0-4.053-1.9.719.719,0,0,1,0-1.438A6.685,6.685,0,0,1,16.3,17,.716.716,0,0,1,15.2,17.916Z"
      transform="translate(0 -0.563)"
    />
  </svg>
);

export const InstaIcon = ({ width, color, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "46.25"}
    height="46.25"
    viewBox="0 0 46.25 46.25"
  >
    <g
      id="Group_4017"
      data-name="Group 4017"
      transform="translate(-9907 -4925)"
    >
      <g
        id="Group_4016"
        data-name="Group 4016"
        transform="translate(9907 4925)"
      >
        <g id="Group_4015" data-name="Group 4015">
          <g id="_025-instagram" data-name="025-instagram">
            <path
              id="Path_12548"
              data-name="Path 12548"
              d="M6.775,46.25h32.7a6.783,6.783,0,0,0,6.775-6.775V6.775A6.783,6.783,0,0,0,39.475,0H6.775A6.783,6.783,0,0,0,0,6.775v32.7A6.783,6.783,0,0,0,6.775,46.25ZM2.71,6.775A4.07,4.07,0,0,1,6.775,2.71h32.7A4.07,4.07,0,0,1,43.54,6.775v32.7a4.07,4.07,0,0,1-4.065,4.065H6.775A4.07,4.07,0,0,1,2.71,39.475Zm0,0"
              fill={color || "#ffffff"}
            />
            <path
              id="Path_12549"
              data-name="Path 12549"
              d="M133.195,145.39A12.195,12.195,0,1,0,121,133.195,12.209,12.209,0,0,0,133.195,145.39Zm0-21.68a9.485,9.485,0,1,1-9.485,9.485A9.5,9.5,0,0,1,133.195,123.71Zm0,0"
              transform="translate(-110.07 -110.07)"
              fill={color || "#ffffff"}
            />
            <path
              id="Path_12550"
              data-name="Path 12550"
              d="M365.065,69.13A4.065,4.065,0,1,0,361,65.065,4.07,4.07,0,0,0,365.065,69.13Zm0-5.42a1.355,1.355,0,1,1-1.355,1.355A1.357,1.357,0,0,1,365.065,63.71Zm0,0"
              transform="translate(-328.39 -55.49)"
              fill={color || "#ffffff"}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const TwitterIcon = ({ width, color, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "53.331"}
    height="43.649"
    viewBox="0 0 53.331 43.649"
  >
    <path
      id="_043-twitter"
      data-name="043-twitter"
      d="M53.331,5.83c-3.355.155-3.281.142-3.661.174L51.657.329s-6.2,2.281-7.774,2.689A12.443,12.443,0,0,0,29.229,1.841,10.149,10.149,0,0,0,24.35,12.277,27.07,27.07,0,0,1,6.813,2.4L5.342.636l-1.1,2.019a12.2,12.2,0,0,0-1.3,8.213,11.8,11.8,0,0,0,1.143,3.206l-1.262-.489-.15,2.109a11.252,11.252,0,0,0,1.908,6.689,11.894,11.894,0,0,0,1.486,1.833l-.652-.1.8,2.416a11.747,11.747,0,0,0,6.039,7c-2.816,1.2-5.091,1.958-8.83,3.188L0,37.841l3.16,1.728a33.166,33.166,0,0,0,9.668,3.519c9.35,1.466,19.877.272,26.964-6.111C45.762,31.6,47.721,23.954,47.314,16a4.972,4.972,0,0,1,.929-3.237C49.567,10.991,53.323,5.842,53.331,5.83Zm-7.586,5.057A8.1,8.1,0,0,0,44.2,16.155c.411,8.027-1.774,14.253-6.492,18.5C32.192,39.622,23.3,41.57,13.312,40A24.189,24.189,0,0,1,8.086,38.45a82.868,82.868,0,0,0,9.466-3.891l5.458-2.583-6.026-.386a8.522,8.522,0,0,1-6.767-3.855,10.808,10.808,0,0,0,2.294-.382l5.747-1.6-5.8-1.419A8.631,8.631,0,0,1,7.19,20.667a8.84,8.84,0,0,1-1.143-2.521,14.467,14.467,0,0,0,2.351.375l5.364.531-4.25-3.315A8.946,8.946,0,0,1,6.123,6.31C15.68,16.222,26.9,15.477,28.03,15.739a18.5,18.5,0,0,0-.32-2.65A7.174,7.174,0,0,1,30.864,4.5a9.333,9.333,0,0,1,11.011.912A2.8,2.8,0,0,0,44.44,6.1a21.6,21.6,0,0,0,2.114-.643L45.207,9.3h1.72C46.6,9.741,46.213,10.262,45.744,10.887Zm0,0"
      transform="translate(0 0)"
      fill={color || "#ffffff"}
    />
  </svg>
);

export const YesKeIcon = ({ width, color, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="_1561325261622470166"
    data-name="1561325261622470166"
    width={width || "55.463"}
    height="35.987"
    viewBox="0 0 55.463 35.987"
  >
    <path
      id="Path_275"
      data-name="Path 275"
      d="M201.648,54.5a.916.916,0,0,0-1.12.653l-.852,3.231-2.107-.661a2.921,2.921,0,0,1-5.333-1.565l-2.469-2.549,1.576-3.956a.917.917,0,1,0-1.7-.678l-1.8,4.507a.917.917,0,0,0,.193.977l2.8,2.888-1.471,7.506a.881.881,0,0,0,.054.517,1.408,1.408,0,0,0,.108.272l2.77,5.288-3.24,5.822a1.4,1.4,0,1,0,2.453,1.365l3.609-6.484a1.4,1.4,0,0,0,.017-1.334l-1.389-2.651,3.186,1.781.3,5.75a1.4,1.4,0,0,0,1.4,1.331l.074,0a1.4,1.4,0,0,0,1.329-1.475l-.34-6.524a1.4,1.4,0,0,0-.717-1.152l-3.415-1.909,1.188-6.06,3.293,1.033a.917.917,0,0,0,1.161-.641l1.1-4.159A.917.917,0,0,0,201.648,54.5Z"
      transform="translate(-166.263 -42.849)"
    />
    <circle
      id="Ellipse_1"
      data-name="Ellipse 1"
      cx="2.578"
      cy="2.578"
      r="2.578"
      transform="translate(25.256 13.493) rotate(-49.217)"
    />
    <path
      id="Path_276"
      data-name="Path 276"
      d="M61.452,157.587H54.085a.4.4,0,0,0-.4.4v12.715a.4.4,0,0,0,.4.4h7.367a.4.4,0,0,0,.4-.4V157.991A.4.4,0,0,0,61.452,157.587Zm-3.683,1.589a1.753,1.753,0,1,1-1.753,1.753A1.753,1.753,0,0,1,57.768,159.177Zm0,10.546a2.775,2.775,0,1,1,2.775-2.775A2.775,2.775,0,0,1,57.768,169.723Z"
      transform="translate(-47.53 -139.531)"
    />
    <circle
      id="Ellipse_2"
      data-name="Ellipse 2"
      cx="0.946"
      cy="0.946"
      r="0.946"
      transform="translate(9.292 26.47)"
    />
    <circle
      id="Ellipse_3"
      data-name="Ellipse 3"
      cx="0.598"
      cy="0.598"
      r="0.598"
      transform="translate(9.64 20.801)"
    />
    <path
      id="Path_277"
      data-name="Path 277"
      d="M65.444,278.688h-.7v2.935h-2.79v.7h6.278v-.7h-2.79Z"
      transform="translate(-54.858 -246.756)"
    />
    <path
      id="Path_278"
      data-name="Path 278"
      d="M370.589,157.587h-7.367a.4.4,0,0,0-.4.4v12.715a.4.4,0,0,0,.4.4h7.367a.4.4,0,0,0,.4-.4V157.991A.4.4,0,0,0,370.589,157.587Zm-3.683,1.589a1.753,1.753,0,1,1-1.753,1.753A1.753,1.753,0,0,1,366.906,159.177Zm0,10.546a2.775,2.775,0,1,1,2.775-2.775A2.775,2.775,0,0,1,366.906,169.723Z"
      transform="translate(-321.247 -139.531)"
    />
    <circle
      id="Ellipse_4"
      data-name="Ellipse 4"
      cx="0.946"
      cy="0.946"
      r="0.946"
      transform="translate(44.713 26.47)"
    />
    <circle
      id="Ellipse_5"
      data-name="Ellipse 5"
      cx="0.598"
      cy="0.598"
      r="0.598"
      transform="translate(45.061 20.801)"
    />
    <path
      id="Path_279"
      data-name="Path 279"
      d="M374.582,278.688h-.7v2.935h-2.79v.7h6.278v-.7h-2.79Z"
      transform="translate(-328.574 -246.756)"
    />
    <path
      id="Path_280"
      data-name="Path 280"
      d="M95.128,59.2l-1.17-.419-1.671,9.481.423.014Z"
      transform="translate(-81.713 -52.042)"
    />
    <path
      id="Path_281"
      data-name="Path 281"
      d="M53.107,70.866,50.48,61.848l-.772.477,3.028,8.748Z"
      transform="translate(-44.013 -54.761)"
    />
    <path
      id="Path_282"
      data-name="Path 282"
      d="M108.482,91.621l.371.2,6.259-7-.814-.4Z"
      transform="translate(-96.052 -74.746)"
    />
    <path
      id="Path_283"
      data-name="Path 283"
      d="M366.712,59.2l2.418,9.076.423-.014-1.671-9.481Z"
      transform="translate(-324.694 -52.042)"
    />
    <path
      id="Path_284"
      data-name="Path 284"
      d="M407.059,61.848l-2.626,9.018.37.207,3.028-8.748Z"
      transform="translate(-358.092 -54.761)"
    />
    <path
      id="Path_285"
      data-name="Path 285"
      d="M324.082,91.621l-5.816-7.2-.814.4,6.259,7Z"
      transform="translate(-281.078 -74.746)"
    />
    <path
      id="Path_286"
      data-name="Path 286"
      d="M270.033,22.641a.613.613,0,1,0,.583,1.031.815.815,0,0,0,.441-.64h0v-.006c0-.011,0-.022,0-.033V19.521l-3.4.935v3.287a.859.859,0,0,0-.679.09.613.613,0,1,0,.583,1.031.787.787,0,0,0,.44-.7V21.821l2.712-.746v1.476A.858.858,0,0,0,270.033,22.641ZM268,21.465v-.746l2.712-.746v.746Z"
      transform="translate(-235.997 -17.285)"
    />
    <path
      id="Path_287"
      data-name="Path 287"
      d="M402.523,3.216l0-.005-.013-.03L401.116,0l-2.739,2.219,1.318,3.011a.859.859,0,0,0-.586.355.7.7,0,0,0,.01.974.7.7,0,0,0,.938-.264.787.787,0,0,0,.12-.821l-.938-2.143,2.185-1.771.592,1.352a.858.858,0,0,0-.587.355.7.7,0,0,0,.01.974.7.7,0,0,0,.938-.264.815.815,0,0,0,.147-.763Zm-3.427-.21-.3-.684L400.982.551l.3.684Z"
      transform="translate(-352.73)"
    />
    <path
      id="Path_288"
      data-name="Path 288"
      d="M133.389,25.609,130.9,27.75a.858.858,0,0,0-.511-.456.613.613,0,1,0-.4,1.114.787.787,0,0,0,.82-.125l1.774-1.524,2.333,1.571-1.119.962a.858.858,0,0,0-.512-.457.613.613,0,1,0-.4,1.114.815.815,0,0,0,.773-.083h0l0,0,.025-.022,2.633-2.262Zm-.541.918.566-.486,2.333,1.571-.566.486Z"
      transform="translate(-114.599 -22.674)"
    />
    <path
      id="Path_289"
      data-name="Path 289"
      d="M4.242,26.768l0-.005-.017-.028-1.8-2.969L0,26.326l1.7,2.811a.859.859,0,0,0-.534.429.7.7,0,0,0,.138.964.7.7,0,0,0,.895-.385.787.787,0,0,0,.011-.83L1,27.316l1.933-2.043L3.7,26.535a.858.858,0,0,0-.535.43.7.7,0,0,0,.138.964.7.7,0,0,0,.895-.385.815.815,0,0,0,.046-.776Zm-3.425.242L.43,26.372,2.363,24.33l.387.638Z"
      transform="translate(0 -21.043)"
    />
    <path
      id="Path_290"
      data-name="Path 290"
      d="M332.158,30.53v3.377a.858.858,0,0,0-.679.09.613.613,0,1,0,.583,1.031.787.787,0,0,0,.44-.7V31.3l1.229.229.063-.338-.008,0,.058-.225Z"
      transform="translate(-293.107 -27.032)"
    />
    <path
      id="Path_291"
      data-name="Path 291"
      d="M451.381,55.9l-1.3,3.118a.859.859,0,0,0-.662-.177c-.424.051-.736.356-.7.681a.7.7,0,0,0,.839.495.787.787,0,0,0,.676-.481l1.162-2.794,1.047.683.188-.288-.007,0,.14-.186Z"
      transform="translate(-397.307 -49.492)"
    />
    <path
      id="Path_292"
      data-name="Path 292"
      d="M74.327,23.751a.787.787,0,0,0,.6-.576l.736-2.935,1.136.521.143-.312-.008,0,.111-.2-1.529-.829-.821,3.276a.858.858,0,0,0-.68-.077.613.613,0,1,0,.315,1.141Z"
      transform="translate(-64.996 -17.187)"
    />
    <path
      id="Path_293"
      data-name="Path 293"
      d="M44.052,149.922a.128.128,0,1,0-.059-.25.946.946,0,0,0-.635.423.987.987,0,0,0-.088.708.128.128,0,0,0,.158.089l.014,0a.129.129,0,0,0,.076-.153.733.733,0,0,1,.063-.51A.7.7,0,0,1,44.052,149.922Z"
      transform="translate(-38.286 -132.519)"
    />
    <path
      id="Path_294"
      data-name="Path 294"
      d="M38.094,146.657l.02-.006a.129.129,0,0,0,.078-.147,1.191,1.191,0,0,1,.147-.82,1.176,1.176,0,0,1,.8-.473.128.128,0,0,0-.049-.252,1.312,1.312,0,0,0-1.151,1.6A.128.128,0,0,0,38.094,146.657Z"
      transform="translate(-33.571 -128.348)"
    />
    <path
      id="Path_295"
      data-name="Path 295"
      d="M130.128,153.442a.128.128,0,0,0,.159-.088.946.946,0,0,0-.059-.761.987.987,0,0,0-.574-.423.128.128,0,0,0-.155.094.125.125,0,0,0,0,.014.129.129,0,0,0,.1.141.734.734,0,0,1,.414.3.7.7,0,0,1,.033.559A.129.129,0,0,0,130.128,153.442Z"
      transform="translate(-114.658 -134.731)"
    />
    <path
      id="Path_296"
      data-name="Path 296"
      d="M132.768,147.784a.128.128,0,0,0-.16.086.133.133,0,0,0,0,.021.129.129,0,0,0,.09.139,1.191,1.191,0,0,1,.643.529,1.176,1.176,0,0,1,.02.931.128.128,0,0,0,.244.081,1.312,1.312,0,0,0-.834-1.786Z"
      transform="translate(-117.409 -130.846)"
    />
    <path
      id="Path_297"
      data-name="Path 297"
      d="M438.266,153.442a.128.128,0,0,0,.159-.088.946.946,0,0,0-.059-.761.987.987,0,0,0-.574-.423.128.128,0,0,0-.155.094.119.119,0,0,0,0,.014.129.129,0,0,0,.1.141.734.734,0,0,1,.414.3.7.7,0,0,1,.033.559A.129.129,0,0,0,438.266,153.442Z"
      transform="translate(-387.49 -134.731)"
    />
    <path
      id="Path_298"
      data-name="Path 298"
      d="M440.906,147.784a.128.128,0,0,0-.16.086.133.133,0,0,0,0,.021.129.129,0,0,0,.09.139,1.191,1.191,0,0,1,.643.529,1.176,1.176,0,0,1,.02.931.128.128,0,0,0,.244.081,1.312,1.312,0,0,0-.833-1.786Z"
      transform="translate(-390.241 -130.846)"
    />
    <path
      id="Path_299"
      data-name="Path 299"
      d="M436.875,271.6a.987.987,0,0,0,.087-.708.128.128,0,0,0-.158-.088l-.014,0a.129.129,0,0,0-.076.153.733.733,0,0,1-.062.51.7.7,0,0,1-.47.3.128.128,0,1,0,.059.25A.946.946,0,0,0,436.875,271.6Z"
      transform="translate(-386.117 -239.768)"
    />
    <path
      id="Path_300"
      data-name="Path 300"
      d="M438.911,271.352l-.02.006a.129.129,0,0,0-.077.147,1.191,1.191,0,0,1-.145.82,1.176,1.176,0,0,1-.8.475.128.128,0,1,0,.05.252,1.312,1.312,0,0,0,1.147-1.6A.128.128,0,0,0,438.911,271.352Z"
      transform="translate(-387.605 -240.258)"
    />
    <path
      id="Path_301"
      data-name="Path 301"
      d="M355.437,273.163a.734.734,0,0,1-.458-.233.7.7,0,0,1-.124-.546.128.128,0,1,0-.255-.03.946.946,0,0,0,.182.741.987.987,0,0,0,.636.324.128.128,0,0,0,.137-.118.118.118,0,0,0,0-.014A.129.129,0,0,0,355.437,273.163Z"
      transform="translate(-313.961 -241.047)"
    />
    <path
      id="Path_302"
      data-name="Path 302"
      d="M350.633,273.69a1.191,1.191,0,0,1-.721-.417,1.176,1.176,0,0,1-.172-.915.128.128,0,0,0-.254-.04,1.312,1.312,0,0,0,1.114,1.627.128.128,0,0,0,.143-.111.131.131,0,0,0,0-.021A.129.129,0,0,0,350.633,273.69Z"
      transform="translate(-309.419 -241.019)"
    />
    <path
      id="Path_303"
      data-name="Path 303"
      d="M352.49,150.084a.128.128,0,0,0-.1-.237.946.946,0,0,0-.556.523.987.987,0,0,0,.031.713.128.128,0,0,0,.17.061l.013-.007a.129.129,0,0,0,.049-.164.733.733,0,0,1-.022-.514A.7.7,0,0,1,352.49,150.084Z"
      transform="translate(-311.473 -132.669)"
    />
    <path
      id="Path_304"
      data-name="Path 304"
      d="M346.665,146.318a1.176,1.176,0,0,1,.712-.6.128.128,0,0,0-.09-.241,1.312,1.312,0,0,0-.869,1.77.128.128,0,0,0,.167.07l.019-.009a.129.129,0,0,0,.052-.158A1.19,1.19,0,0,1,346.665,146.318Z"
      transform="translate(-306.644 -128.802)"
    />
    <path
      id="Path_305"
      data-name="Path 305"
      d="M127.189,274.239a.987.987,0,0,0,.1-.706.128.128,0,0,0-.156-.091l-.014,0a.129.129,0,0,0-.078.152.734.734,0,0,1-.071.509.7.7,0,0,1-.476.294.128.128,0,1,0,.055.251A.946.946,0,0,0,127.189,274.239Z"
      transform="translate(-111.91 -242.106)"
    />
    <path
      id="Path_306"
      data-name="Path 306"
      d="M129.162,274.09l-.02.006a.129.129,0,0,0-.08.146,1.191,1.191,0,0,1-.16.817,1.176,1.176,0,0,1-.81.46.128.128,0,0,0,.045.253,1.312,1.312,0,0,0,1.176-1.582A.128.128,0,0,0,129.162,274.09Z"
      transform="translate(-113.322 -242.682)"
    />
    <path
      id="Path_307"
      data-name="Path 307"
      d="M44.205,275.379a.734.734,0,0,1-.481-.18.7.7,0,0,1-.184-.529.128.128,0,1,0-.257,0,.946.946,0,0,0,.264.716.987.987,0,0,0,.668.251.128.128,0,0,0,.123-.133.124.124,0,0,0,0-.014A.129.129,0,0,0,44.205,275.379Z"
      transform="translate(-38.324 -243.083)"
    />
    <path
      id="Path_308"
      data-name="Path 308"
      d="M39.59,276.424a1.191,1.191,0,0,1-.763-.334,1.176,1.176,0,0,1-.273-.89.128.128,0,0,0-.257-.011,1.312,1.312,0,0,0,1.288,1.492.128.128,0,0,0,.13-.126.131.131,0,0,0,0-.021A.129.129,0,0,0,39.59,276.424Z"
      transform="translate(-33.908 -243.548)"
    />
  </svg>
);
