import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "antd/dist/antd.css";
import { ProtectedRoute } from "./pages/ProtectedRoute/ProtectedRoute";
import { QueryClient, QueryClientProvider } from "react-query";
import { MainContext } from "./context/mainContext";
import LandingPage from "./pages/LandingPage/landingPage";
import PreviewPage from "./pages/PreviewPage/previewPage";
import FaqPage from "./pages/Faqs/faqs";
import StreamPage from "./pages/StreamPage/streamPage";

const queryClient = new QueryClient();

function App() {
  const [pageLoading, setPageLoading] = useState(false);
  const ref = useRef(null);
  const {
    dispatch,
    state: { isPlayerActive },
  } = useContext(MainContext);

  useEffect(() => {
    if (!!isPlayerActive) {
      ref?.current?.play();
    } else ref?.current?.pause();
  }, [isPlayerActive]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {/* <Route
            path="/stream"
            element={
              <ProtectedRoute>
                <StreamPage />
              </ProtectedRoute>
            }
          /> */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/stream-preview" element={<PreviewPage />} />
          <Route path="/faqs" element={<FaqPage />} />
          <Route path="/stream" element={<StreamPage />} />
        </Routes>
        <audio
          className="audio"
          ref={ref}
          controls
          loop
          src={
            "https://v2-email-templates-assets.s3.amazonaws.com/burna-mix.mp4"
          }
          type="audio/mpeg"
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: "-9999",
            top: "0",
          }}
        ></audio>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
