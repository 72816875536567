import React, { useContext } from "react";
import GeneralLayout from "../../components/layouts/GeneralLayout/generalLayout";
import { Box } from "../../components/lib/Box/styles";
import Button from "../../components/lib/Button";
import { PreviewPageWrapper } from "./previewPage.styles";
import { MainContext } from "../../context/mainContext";
import { IS_PLAYER_ACTIVE } from "../../context/types";
import { useNavigate } from "react-router-dom";

const PreviewPage = () => {
  const navigate = useNavigate();
  const {
    dispatch,
    state: { isPlayerActive, user },
  } = useContext(MainContext);

  const toggle = () => {
    navigate("/stream");

    if (!!isPlayerActive) {
      dispatch({ type: IS_PLAYER_ACTIVE, payload: !isPlayerActive });
    }
  };
  return (
    <GeneralLayout>
      <PreviewPageWrapper>
        <Box className="damini__inlagos__art">
          <Button onClick={toggle}>STREAM NOW</Button>
        </Box>
      </PreviewPageWrapper>
    </GeneralLayout>
  );
};

export default PreviewPage;
