import styled from "styled-components";
import { Box } from "../../components/lib/Box/styles";

export const LandingPageWrapper = styled(Box)`
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  padding: 20px;

  .damini_logo__wrap {
    max-width: 500px;
    width: 70%;
    img {
      width: 100%;
      height: auto;
    }
  }

  .header__text {
    color: #ffffff;
    font-size: 1.7em;
    margin: 40px 0 10px 0;
    text-align: center;
  }

  .buttons__wrap {
    max-width: 410px;
    justify-content: space-between;
    button {
      margin-top: 40px;
    }
  }

  @media (max-width: 600px) {
    .header__text {
      font-size: 1.4em;
      margin: 40px 0 0 0;
    }
    .buttons__wrap {
      flex-direction: column;
    }
  }
`;
